@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Open+Sans:wght@300&display=swap');

body {
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 30px;
}
h1 > span {
  font-size: 50px;
  letter-spacing: 5px;
}
h2 {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}
h3 {
  font-family: 'Montserrat', sans-serif;
}
p {
  font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    margin: 0;
}

.App {
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
  height: calc(100vh - 4rem);
  background-color: #FAFAFA;
}

.stories {
	filter: drop-shadow(0 10px 20px #aaa);
}

.left {
	display: flex;
	flex-direction: column;
	margin-right: 10rem;
	justify-content: flex-start;
	max-width: 400px;
}

.seeMore {
	padding: 2rem;
	color: #16161d;
	background: white;
	height: -webkit-fill-available;
}

.seeMore input,
.seeMore textarea {
	display: block;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 0.8rem;
	margin-bottom: 0.7rem;
	width: -webkit-fill-available;
	transition: filter 300ms ease-in-out;
	font-size: 0.8rem;
	font-family: inherit;
}

.seeMore input:focus,
.seeMore textarea:focus {
	outline: none;
	filter: drop-shadow(0 2px 5px #ccc);
}

.seeMore button {
	display: block;
	margin: auto;
	margin-top: 1rem;
	background: #16161d;
	filter: drop-shadow(0 3px 5px #ccc);
	color: #333;
	border-radius: 5px;
	border: none;
	width: 200px;
	padding: 0.5rem;
	font-size: 1rem;
	transition: filter 300ms ease-in-out;
}

.seeMore button:focus {
	outline: none;
}

.seeMore button:hover {
	cursor: pointer;
	filter: drop-shadow(0 5px 5px #aaa);
}

.seeMore a {
	display: block;
	font-size: 0.8rem;
	width: fit-content;
	margin: auto;
	margin-top: 2rem;
}

code {
	font-family: 'Open Sans', sans-serif;}

.updates {
	max-height: 30vh;
	overflow: scroll;
}

.updates p {
	margin: 2px;
}

@media only screen and (max-width: 768px) {
	.App {
		flex-direction: column;
		justify-content: flex-start;
		height: auto;
	}

	.left {
		max-width: 100%;
		margin: 0;
		margin-bottom: 2rem;
	}
}

.ctaStory > .ctaContent {
  position: absolute;
  top: 0;
  height: 100%;
  backdrop-filter: blur(1px);
}
.ctaStory > .ctaContent > .ctaInner {
  padding: 0px 16px 16px 16px;
  margin-top: 60%;
  transform: rotate(355deg);
  margin-left: 30px;
  width: 225px;
  background-color: #FAFAFA;
  box-shadow: 0 0 20px rgb(0 0 0 / 60%);
  display: flex;
  flex-direction: column;
}
.ctaStory > .ctaContent > .ctaInner > span {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #333;
  text-decoration: none;
}